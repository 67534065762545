<template>
  <div id="dashboard-custom">
    <b-card no-body v-if="arr.dashboard.length">
      <b-tabs card>
        <b-tab  :title="value.name"
                v-for="(value, key) in arr.dashboard"
                :key="value.id"
                @click="setDashboardActive(value)"
                :active="value.id == tabActive">
        
          <b-card-text>
            <div class="buttons-dashboard-widgets">

              <b-button type="button" 
                        variant="outline-dark" 
                        size="sm" 
                        class="btn-pill float-right dashboard-action-widget"  
                        v-b-tooltip.hover.top title="Acciones sobre el Dashboard"                       
                        :id="'popover-dashboard-actions-'+key">
                <b-icon icon="grid1x2"></b-icon>
              </b-button>          
              <b-popover triggers="hover focus"                         
                        placement="left"                        
                        :target="'popover-dashboard-actions-'+key">                            

                <b-dropdown size="sm" variant="link" toggle-class="text-decoration-none" no-caret class="pull-right mb-1">
                  <template v-slot:button-content>
                    <b-button type="button" variant="outline-danger" size="sm" class="float-right" v-b-tooltip.hover.top title="Opciones de widget">
                      <i class="icon-calculator"></i> Widget
                    </b-button>
                  </template>

                  <b-dropdown-item @click="openAddWidget(value)" v-b-tooltip.hover.left title="Agregar Widgets">
                    <i class="fa fa-plus"></i> Agregar
                  </b-dropdown-item>
                  <b-dropdown-item @click="openConfigWidget(value)" v-b-tooltip.hover.left title="Configurar Widgets">
                    <i class="icon-settings"></i> Configurar
                  </b-dropdown-item>
                </b-dropdown>

                <b-dropdown size="sm" variant="link" toggle-class="text-decoration-none" no-caret class="pull-right mb-1">
                  <template v-slot:button-content>
                    <b-button type="button" variant="outline-success" size="sm" class="float-right" v-b-tooltip.hover.top title="Opciones de panel de control">
                      <i class="icon-speedometer"></i> Panel de Control
                    </b-button>
                  </template>

                  <b-dropdown-item  v-if="arr.dashboard.length" @click="openNewDashboard()" v-b-tooltip.hover.left title="Agregar Panel de Control">
                    <i class="fa fa-plus"></i> Agregar
                  </b-dropdown-item>
                  <b-dropdown-item @click="openEditDashboard(value)" v-b-tooltip.hover.left title="Editar  Panel de Control">
                    <i class="fa fa-edit"></i> Editar
                  </b-dropdown-item>
                  <b-dropdown-item @click="openDeleteDashboard(value)" v-b-tooltip.hover.left title="Eliminar  Panel de Control">
                    <i class="fa fa-trash"></i> Eliminar
                  </b-dropdown-item>
                </b-dropdown>

              </b-popover> 
            </div>

            <div class="card-columns" :style="'column-count:'+ dashboardColumns">
              <div v-for="item in JSON.parse(value.data)"
                   :key="item.id">

                <Widget :configWidget="getConfigWidget(item)" :reference="item.reference" :hasAccess="getHasAccessWidget(item)" :isCustom="item.php_id > 0"/>

              </div>
            </div>

            <b-row v-if="value.data==null || value.data=='[]'">
              <b-col>
                <b-jumbotron header="Widget"
                            lead="Agregá diferentes widgets para comenzar a controlar toda la información de la empresa"
                            bg-variant="secondary">

                  <b-button @click="openAddWidget(value)" variant="dark" type="button">Agregar</b-button>
                </b-jumbotron>
              </b-col>
            </b-row>

          </b-card-text>

        </b-tab>
      </b-tabs>
    </b-card>
    <div v-else class="jumbotron-dashboard">
      <b-jumbotron header="Panel de Control"
                   lead="Cree multiples dashboard para monitorear todo lo que ocurre en la empresa"
                   bg-variant="dark">

        <b-button @click="openNewDashboard()" variant="outline-secondary" type="button">Crear</b-button>
      </b-jumbotron>
    </div>

    <!-- ########################### -->
    <!-- #####     MODALES     ##### -->
    <!-- ########################### -->

    <!-- CRUD DASHBOARD -->
    <b-modal v-model="modal.form.active"
              header-bg-variant="dark"
              header-text-variant="white">
      <div slot="modal-header">
        {{this.modal.form.title}}
      </div>

      <b-row>
        <b-col md="12">
          <b-form-group label="Nombre">
            <b-form-input type="text"
                          size="sm"
                          v-model="form.name"
                          required
                          placeholder="Ingresar nombre del rol">
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group label="Columnas">
            <b-form-input type="number"
                          size="sm"
                          v-model="form.columns"
                          required
                          placeholder="Ingresar la cantidad de columnas del dashboard">
            </b-form-input>
          </b-form-group>
        </b-col>        
      </b-row>

      <div slot="modal-footer">
        <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
        <b-button variant="dark" @click="saveDashboard()">Guardar</b-button>        
      </div>
    </b-modal>

    <!-- SELECT WIDGET -->
    <b-modal v-model="modal.widget.active"
              header-bg-variant="dark"
              header-text-variant="white"
              no-close-on-esc
              no-close-on-backdrop
              hide-header-close>

      <div slot="modal-header">
        {{this.modal.widget.title}}
      </div>

      <div role="tablist" v-if="arr.widget.length">
        <b-card no-body class="mb-1" v-for="(item, index) in arr.widget" :key="item.id" v-show="validateActiveModule(item)">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle="'accordion-' + index" variant="secondary" class="text-left">
              <b>Módulo:</b> {{item.name}}
            </b-button>
          </b-card-header>
          <b-collapse :id="'accordion-'+ index" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>

              <b-list-group>
                <b-list-group-item href="#"
                                   class="flex-column align-items-start"                                                                      
                                   v-for="value in item.widget"
                                   :key="value.id"
                                   v-b-tooltip.hover title="Click para agregar widget"
                                   @click="confirmAddWidget(value)">

                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">{{value.title}}</h5>
                    <small>{{value.type}}</small>
                  </div>
                  <p class="mb-1">
                    {{value.description}}
                  </p>

                </b-list-group-item>
              </b-list-group>

            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
      <b-badge v-else variant="warning" class="p-3 badge-dashboard-widget-config">No hay widget para agregar</b-badge>

      <template v-slot:modal-footer>
        <button type="button" class="btn btn-outline-secondary mr-1" @click="cancelAddWidget()">Cancelar</button>
      </template>
    </b-modal>

    <!-- ORDER AND DELETE WIDGET -->
    <b-modal v-model="modal.widgetConfig.active"
              header-bg-variant="dark"
              header-text-variant="white"
              no-close-on-esc
              no-close-on-backdrop
              hide-header-close>

      <div slot="modal-header">
        {{this.modal.widgetConfig.title}}
      </div>

      <b-list-group v-if="arr.widgetDashboard.length">
        <draggable
          :list="arr.widgetDashboard"
          :disabled="false"
          @start="dragging = true"
          @end="dragging = false"
        >

          <b-list-group-item href="#"
                             class="flex-column align-items-start"
                             v-for="element in arr.widgetDashboard"
                             :key="element.id">

            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">{{element.title}}</h5>
              <small>{{element.type}}</small>
            </div>

            <b-button @click="quitarWidget(element)" type="button" variant="outline-danger" size="sm" class="btn-pill float-right" v-b-tooltip.hover title="Quitar Widgets">
              <i class="fa fa-trash"></i>
            </b-button>

            <p class="mb-1">
              {{element.description}}
            </p>

          </b-list-group-item>

        </draggable>
      </b-list-group>
      <b-badge v-else variant="warning" class="p-3 badge-dashboard-widget-config">No hay widget para ordenar</b-badge>

      <template v-slot:modal-footer>
        <button type="button" class="btn btn-outline-secondary mr-1" @click="cancelConfigWidget()">Cancelar</button>
        <button type="button" class="btn btn-dark" @click="saveConfigWidget()">Guardar</button>
      </template>
    </b-modal>

  </div>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'
  import Helper from '@/handler/helper'
  import Profiles from '@/config/profiles'
  import Widget from '@/components/inc/widget/widget'
  import draggable from 'vuedraggable'
  import Funciones from '@/handler/funciones'
  import Storage from '@/handler/storageLocal'

  export default {
    components: {
      draggable,
      Widget
    },
    props: {
      profileID: {
        type: Number,
        required: true
      },
    },
    data: () => {
      return {
        configWidget: {
          module_id: 0,
          profile_id: 0,
          elements: {}
        },
        form: {
          id: 0,
          name: '',
          columns: 0,
          profile_id: 0,
        },
        modal: {
          form: {
            active: false,
            title: ''
          },
          widget: {
            active: false,
            title: ''
          },
          widgetConfig: {
            active: false,
            title: ''
          }
        },
        arr: {
          dashboard: [],
          widget: [],
          widgetDashboard: []
        },
        dashboardSelection: {},
        dashboardActive: {},
        dashboardColumns: 3,
        tabActive: 0,
      }
    },
    created () {
      this.configWidget.profile_id = this.profileID
      this.form.profile_id = this.profileID

      window.addEventListener('resize', this.showColumnsDashboard);
    },
    mounted() {
      this.load()

      this.restoreStatusTabsStorage()
    },
    destroyed() {
      window.removeEventListener("resize", this.showColumnsDashboard);
    },          
    methods: {
      load () {
        this.arr.dashboard = []
        this.arr.widget = []

        this.loadDashboard()
        this.loadWidget()
      },
      loadDashboard () {
        this.arr.dashboard = Session.getSession().auth.user.dashboard        
        if(this.arr.dashboard.length) {
          this.dashboardActive = this.arr.dashboard[0]
          this.showColumnsDashboard()
        }        
      },
      loadWidget () {
        var arrWidget = Session.getSession().auth.user.permissions.widget
        var modules = []
        var widget = []
        var module_name = ''

        for (const prop in arrWidget) {
          modules[arrWidget[prop].module.name] = {}
          widget[arrWidget[prop].module.name] = []
        }

        for (const prop in arrWidget) {
          if(module_name != arrWidget[prop].module.name) {

            modules[arrWidget[prop].module.name] = {  id: arrWidget[prop].module.id, name: arrWidget[prop].module.name,}
            modules[arrWidget[prop].module.name]["widget"] = widget[arrWidget[prop].module.name]

            module_name = arrWidget[prop].module.name
          }

          widget[arrWidget[prop].module.name].push(arrWidget[prop])
        }

        for (const prop in modules) {
          this.arr.widget.push(modules[prop])
        }
      },
      openNewDashboard () {
        this.form.id = 0
        this.form.name = ''
        this.form.columns = 3

        this.modal.form.title = "Crear Nuevo Panel de Control"
        this.modal.form.active = true
      },
      openEditDashboard (value) {
        this.form.id = value.id
        this.form.name = value.name
        this.form.columns = value.columns

        this.modal.form.title = "Editar Panel de Control --> " + value.name
        this.modal.form.active = true
      },
      openDeleteDashboard (value) {
        this.form.id = value.id
        this.form.name = value.name

        this.$bvModal.msgBoxConfirm('¿Desea borrar el panel de control (' + this.form.name + ') y todos los widget configurados?', {
          title: 'Borrar >> Panel de control',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: "danger",
          headerTextVariant: "white",
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            this.deleteDashboard()
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      saveDashboard () {
        let loader = this.$loading.show();
        if(this.form.id) {
          var result = serviceAPI.editar(this.form);
        } else {
          var result = serviceAPI.agregar(this.form);
        }

        result.then((response) => {
          var result_1 = serviceAPI.getSessionUser();
          result_1.then((response_1) => {
            var data = response_1.data

            Session.setSessionAuth(data)
            this.modal.form.active = false
            this.load()
            loader.hide()
            this.$awn.success("Panel de control guardado");
          })
          .catch(error_1 => {
            loader.hide()
            this.$awn.alert(Error.showError(error_1))
          })
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },
      deleteDashboard () {
        let loader = this.$loading.show();
        if (this.form.id) {
          var result = serviceAPI.eliminar(this.form.id);
        }

        result.then((response) => {
          var result_1 = serviceAPI.getSessionUser();
          result_1.then((response_1) => {
            var data = response_1.data

            Session.setSessionAuth(data)
            this.modal.form.active = false
            this.load()
            loader.hide()
            this.$awn.success("Panel de control eliminado");
          })
          .catch(error_1 => {
            loader.hide()
            this.$awn.alert(Error.showError(error_1))
          })
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },
      setDashboardActive(dashboard) {
        this.dashboardActive = dashboard
        this.showColumnsDashboard()

        this.saveStatusTabsStorage()
      },
      showColumnsDashboard() {
        if(window.innerWidth > 992) {
            this.dashboardColumns = this.dashboardActive.columns            
        } else {
            this.dashboardColumns = 1
        }                
      },
      openAddWidget (value) {
        this.modal.widget.title = "Agregar Widgets"
        this.modal.widget.active = true

        this.dashboardSelection = value
      },
      confirmAddWidget (item) {
        this.$bvModal.msgBoxConfirm('Agregar el widget (' + item.title + ') al panel de control?', {
          title: 'Widget >> Agregar',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'success',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: "success",
          headerTextVariant: "white",
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            this.addWidget(item)
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      cancelAddWidget (){
        this.modal.widget.active = false
        this.dashboardSelection = {}
      },
      addWidget (item) {
        let loader = this.$loading.show();

        var arrWidget = []
        if(this.dashboardSelection.data) {
          arrWidget = JSON.parse(this.dashboardSelection.data)
        }
        arrWidget.push(item)

        var data = {
          id: this.dashboardSelection.id,
          data: arrWidget,
          type: 'add'
        }

        var result = serviceAPI.actualizarContenidoDashboard(data);

        result.then((response) => {
           var result_1 = serviceAPI.getSessionUser();
          result_1.then((response_1) => {
            var data = response_1.data

            Session.setSessionAuth(data)
            this.modal.widget.active = false
            this.load()
            loader.hide()
            this.$awn.success("Dashboard actualizado");
          })
          .catch(error_1 => {
            loader.hide()
            this.$awn.alert(Error.showError(error_1))
          })
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },
      getConfigWidget(item){
        var data = {
          module_id: item.module_id,
          profile_id: this.profileID,
        }
        return data
      },
      getHasAccessWidget(item) {
        return Helper.hasAccessWidget(this.getConfigWidget(item), item.reference)
      },
      openConfigWidget(item) {
        this.modal.widgetConfig.title = "Configurar Widgets"
        this.modal.widgetConfig.active = true

        this.dashboardSelection = item

        if(item.data) {
          this.arr.widgetDashboard = JSON.parse(item.data)
        } else {
          this.arr.widgetDashboard = []
        }
      },
      cancelConfigWidget(){
        this.modal.widgetConfig.active = false
      },
      saveConfigWidget() {
        let loader = this.$loading.show();

        var data = {
          id: this.dashboardSelection.id,
          data: this.arr.widgetDashboard,
          type: 'config'
        }

        var result = serviceAPI.actualizarContenidoDashboard(data);

        result.then((response) => {
          var result_1 = serviceAPI.getSessionUser();
          result_1.then((response_1) => {
            var data = response_1.data

            Session.setSessionAuth(data)
            this.modal.widgetConfig.active = false
            this.load()
            loader.hide()
            this.$awn.success("Widget configurados");
          })
          .catch(error_1 => {
            loader.hide()
            this.$awn.alert(Error.showError(error_1))
          })
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },
      quitarWidget (value) {
        var i = this.arr.widgetDashboard.indexOf( value );

        if ( i !== -1 ) {
          this.arr.widgetDashboard.splice( i, 1 );
        }
      },
      validateActiveModule(item) {
        return Helper.moduleEnabled(item.id)
      },    

      // TABS SAVE
      saveStatusTabsStorage() {
        Storage.setValue('tabs_view_dashboard', JSON.stringify(this.dashboardActive.id))
      },
      restoreStatusTabsStorage() {
        if(Storage.getValue('tabs_view_dashboard')) {
          this.tabActive = JSON.parse(Storage.getValue('tabs_view_dashboard'))

          this.arr.dashboard.forEach((element)=> {
            if(element.id == this.tabActive) {
              this.dashboardActive = element
            }            
          })         
          this.showColumnsDashboard()          
        }         
      },               
    }
  }
</script>
<style>
  .buttons-dashboard-widgets {
    position: absolute;
    top: 10px;
    right: 15px;
    z-index: 999;
  }
  .jumbotron-dashboard {
    z-index: 1000;
  }
  .badge-dashboard-widget-config {
    font-size: 15px;
    text-align: center;
    width: 100%;
  }
  .dashboard-action-widget {
    line-height: 2;
  }
  #dashboard-custom canvas {
    min-height: 400px;    
  }
  @media (min-width: 992px) {
    .card-columns {
      column-count: 2;
    }
  }  
  @media (max-width: 992px) {
    .card-columns {
      column-count: 1;
    }
  }  
</style>
